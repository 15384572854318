import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import LaningPage from "../views/Landingpage/LaningPage";
import AuthRoutes from "./AuthRoutes/AuthRoutes";
import BlogRoutes from "./BlogRoutes/BlogRoutes";
import CardRoutes from "./CardRoutes/CardRoutes";
import ReaderRoutes from "./ReaderRoutes/ReaderRoutes"


class AppRoutes extends React.Component {
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<LaningPage></LaningPage>} />
          <Route path="/Auth/*" element={<AuthRoutes></AuthRoutes>}></Route>
          <Route path="/Readers/*" element={<ReaderRoutes></ReaderRoutes>}></Route>
          <Route path="/Blogs/*" element={<BlogRoutes></BlogRoutes>}></Route>
          <Route path="/Cards/*" element={<CardRoutes></CardRoutes>}></Route>
        </Routes>
      </>
    );
  }
}
export default AppRoutes;
