import React from "react";
import { apiService, ApiService } from "../../env/apiService";

class SigninHandler extends React.Component {
  HandleSignin = async (username, password, email) => {
    if (!username || !password || !email) {
      throw new Error("Vui lòng điền đầy đủ thông tin. ");
    }
    try {
      const body = {
        username,
        password,
        email,
      };
      const data = await apiService.post("/sign", body);
    } catch (error) {
      throw new Error(error.message || "Đăng ký thất bại");
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}
export default SigninHandler;
