import React from "react";
import Headers from "../Layout/Header/Header";

class Blog extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          <div className="div">
            <Headers></Headers>
          </div>
        </div>
      </>
    );
  }
}
export default Blog;
