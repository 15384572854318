import axios from "axios";

class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL: "https://app-soulreader-southasia-dev-001-a3cre6d3cvdrcpfa.southafricanorth-01.azurewebsites.net/api", // Sử dụng đường dẫn tương đối
      timeout: 10000, // 10 giây timeout
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Interceptor để tự động gán Authorization Token (nếu có)
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token"); // Hoặc lấy từ Redux store
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  async get(endpoint, params = {}) {
    return this.request("get", endpoint, { params });
  }

  async post(endpoint, data) {
    return this.request("post", endpoint, { data });
  }

  async put(endpoint, data) {
    return this.request("put", endpoint, { data });
  }

  async patch(endpoint, data) {
    return this.request("patch", endpoint, { data });
  }

  async delete(endpoint) {
    return this.request("delete", endpoint);
  }

  async request(method, endpoint, options = {}) {
    try {
      if (!endpoint) {
        throw new Error("Endpoint không hợp lệ");
      }

      console.log(`📡 Calling API: [${method.toUpperCase()}] /api${endpoint}`);

      const response = await this.api.request({ method, url: endpoint, ...options });

      console.log("✅ API Response:", response.data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    console.error("❌ API Call Failed:", error);

    if (error.response) {
      console.error("⚠️ Response Data:", error.response.data);
      console.error("🔴 Status Code:", error.response.status);
      console.error("📩 Headers:", error.response.headers);

      throw new Error(`API Error: ${error.response.status} - ${error.response.data?.message || error.response.statusText}`);
    } else if (error.request) {
      console.error("🚫 No response received from server", error.request);
      throw new Error("API Error: No response received from server");
    } else {
      console.error("⚙️ Request setup error:", error.message);
      throw new Error(`API Error: ${error.message}`);
    }
  }
}

// Khởi tạo instance dùng chung
const apiService = new ApiService();

export { apiService, ApiService };
