import React from "react";
import "../../Auth/style/Auth.scss";
import { Link } from "react-router-dom";

class SendEmail extends React.Component {
  render() {
    return (
      <>
        <div className="login">
          <div className="div">
            <div className="card">
              <div className="overlap-group">
                <div className="ellipse" />

                <img
                  className="image"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image@2x.png"
                />

                <img
                  className="img"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image-1@2x.png"
                />

                <img
                  className="image-2"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image-2@2x.png"
                />
              </div>
            </div>

            <div className="login-input">
              <div className="text-wrapper-9">ĐẶT LẠI MẬT KHẨU TÀI KHOẢNG</div>
              <div className="container">
                <div className="input-container">
                  <div className="input">
                    <div className="user-name">
                      <div className="overlap-group-4">
                        <label
                          htmlFor="email"
                          className="text-wrapper-8"
                        ></label>
                        <input
                          id="email"
                          type="text"
                          placeholder="E-mail"
                          className="input-field"
                        />
                        <img
                          className="frame"
                          alt="Frame"
                          src="https://c.animaapp.com/v0sAEoVB/img/frame-1@2x.png"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="group-6">
                    <button className="overlap-group-3">
                      <div className="text-wrapper-4">Đặt lại mật khẩu</div>
                    </button>
                  </div>

                  <p className="b-n-kh-ng-c-t-i-kho">
                    <Link  className="span" to="/Auth/login">
                      <span>Đăng nhập tại đây</span>
                    </Link>
                  </p>
                </div>
                <div className="overlap-group-wrapper"></div>
              </div>
            </div>

            <Link to="/">
              <img
                className="logo"
                alt="Logo"
                src="https://c.animaapp.com/v0sAEoVB/img/logo@2x.png"
              />
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default SendEmail;
