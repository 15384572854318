import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

class Headers extends React.Component {
  render() {
    return (
      <>
        <header className="header">
          <div className="group-3">
            <div className="overlap-6">
              <div className="rectangle-9" />

              <Link className="text-wrapper-33" to="/Auth/signin">
                Đăng ký
              </Link>
            </div>

            <div className="overlap-group-8">
              <Link className="text-wrapper-34" to="/Auth/login">
                Đăng nhập
              </Link>
            </div>
          </div>

          <div className="navbar">
            <Link className="text-wrapper-35" to="/">
              Trang chủ
            </Link>
            <Link className="text-wrapper-36" to="/Readers">
              Tarot Reader
            </Link>
            <Link className="text-wrapper-37" to="/Blogs">
              Blog
            </Link>
            <a className="text-wrapper-38" href="#">
              Lịch hẹn
            </a>
            <Link className="text-wrapper-39" to="/Cards">
              Trải bài online
            </Link>
          </div>

          <a href="#">
            <img
              className="group-4"
              alt="Group"
              src="https://c.animaapp.com/3r123mfJ/img/group-47486@2x.png"
            />
          </a>
        </header>
      </>
    );
  }
}
export default Headers;
