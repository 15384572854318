import React from "react";
import { Card } from "../../../utils/cardDTO";
import { apiService } from "../../../env/apiService";
import { motion } from "framer-motion";

class CardResult extends React.Component {
  state = {
    isWatchingSumarize: false,
    sumarise: "",
    selectedCard: [],
    flippedIndex: -1, // Theo dõi thẻ đã lật đến đâu
  };

  WatchSumarizeHandler = async () => {
    this.setState({
      isWatchingSumarize: !this.state.isWatchingSumarize,
    });

    try {
      const query = this.props.selectedCardID
        .map((id) => `cardIds=${id}`)
        .join("&");
      const topicID = this.props.topicSelected;
      const endpoint = `/TopicCardMeanings/Sumary?${query}&topicId=${topicID.id}`;
      const apiResponse = await apiService.get(endpoint, {});
      this.setState({ sumarise: apiResponse });
    } catch (error) {}
  };

  GetCardInformation = async () => {
    const cardIdsQuery = this.props.selectedCardID
      .map((id) => `cardIds=${id}`)
      .join("&");
    console.log("card query" + cardIdsQuery);
    const topicID = this.props.topicSelected;
    const endpoint = `/topiccardmeanings?${cardIdsQuery}&topicId=${topicID.id}`;

    try {
      const apiResponse = await apiService.get(endpoint, {});
      const card = apiResponse.map((card) => Card(card));
      this.setState({ selectedCard: card });
    } catch (error) {}
  };

  componentDidMount() {
    this.GetCardInformation();
  }

  handleCardFlip = (index) => {
    this.setState((prevState) => ({
      flippedIndex: index, // Cập nhật thẻ đã lật xong
    }));
  };

  render() {
    const { selectedCard, isWatchingSumarize, flippedIndex, sumarise } =
      this.state;
    const isWatchingResult = this.props.isWatchingResult;

    return (
      <>
        <div className="select-result">
          <div className="card-result">
            {selectedCard.map((card, index) => (
              <motion.div
                className={`result-box ${isWatchingResult ? "flipped" : ""}`}
                key={index}
                initial={{ rotateY: 0 }}
                animate={{ rotateY: isWatchingResult ? 180 : 0 }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                  delay: index * 0.5,
                }}
                onAnimationComplete={() => this.handleCardFlip(index)} // Khi xoay xong, cập nhật flippedIndex
              >
                <div className="result-back">
                  <img
                    className="element-image"
                    alt="Element"
                    src={card.imageUrl}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Hiển thị meaning khi thẻ đã lật */}
        <div className="meaning-result">
          {selectedCard.map(
            (card, index) =>
              index <= flippedIndex && ( // Chỉ hiển thị khi đã lật xong
                <motion.div
                  className="card-result"
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: 0.2,
                  }}
                >
                  <img
                    className="element-image-detail"
                    alt="Element"
                    src={card.imageUrl}
                  />
                  <div className="meaning-text">
                    <div className="">
                      <div className="">{card.cardName}</div>
                      <p className="">{card.topicMeaning}</p>
                    </div>
                  </div>
                </motion.div>
              )
          )}
        </div>

        {/* Nút xem tổng hợp */}
        <div className="meaning-btn">
          <div
            className="meaning-background"
            onClick={() => this.WatchSumarizeHandler()}
          >
            <div className="meaning-word">Xem tổng hợp</div>
          </div>
        </div>

        {/* Hiển thị tổng hợp */}
        {isWatchingSumarize && (
          <div className="sumarize">
            <div className="card-sumarize">
              {selectedCard.slice(0, 3).map((card, index) => (
                <motion.img
                  key={index}
                  className={`card-${["left", "middle", "right"][index]}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    delay: index * 0.5,
                  }}
                  alt="Card"
                  src={card.imageUrl}
                />
              ))}
            </div>

            <motion.div
              className="text-sumarize"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <div className="sumarize-content">{sumarise}</div>
            </motion.div>
          </div>
        )}
      </>
    );
  }
}

export default CardResult;
