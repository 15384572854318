import React from "react";
import Headers from "../../Layout/Header/Header";
import Footers from "../../Layout/Footer/Footer";
import { motion } from "framer-motion";
import cardImage from "../../../images/Card.png";
import "./CardShuffle.scss";
import CardResult from "./CardResult";
import { apiService } from "../../../env/apiService";
import { Topic } from "../../../utils/cardDTO";

class CardShuffle extends React.Component {
  state = {
    selectedCard: [],
    randomCardKey: [],
    allCard: Array(78).fill(null),
    isWatchingResult: false,
    isShuffuling: false,
    alertMessage: "",
    openTopic: false,
    openQuestion: false,
    topicSelected: null,
    questionSelected: "",
    topics: [],

    error: "",
  };
  HandleFliped = async () => {
    try {
      this.setState({
        isShuffuling: true,
      });
    } catch (error) {}
    this.setState({
      isWatchingResult: true,
    });
  };
  HandleCardClick = (index) => {
    const {selectedCard,randomCardKey} = this.state;

    if (selectedCard.includes(randomCardKey[index])) {
      this.setState({
        selectedCard: selectedCard.filter((card) => card !== randomCardKey[index]),
      });
    } else {
      if (selectedCard.length < 3) {
        this.setState({
          selectedCard: [...selectedCard, randomCardKey[index]],
        });
      }
    }
  };

  componentDidMount() {
    this.GetTopic();
  }

  GetTopic = async () => {
    try {
      const apiResponse = await apiService.get("/topics", {});

      const allTopics = apiResponse.map((topic) => Topic(topic));
      this.setState({
        topics: allTopics,
      });
    } catch (error) {
      console.error("Login failed:", error.message);
      this.setState({
        error: error.message,
      });
    }
  };

  ShuffleCard = () => {
    const topicSelected = this.state.topicSelected;

    if (!topicSelected) {
      this.setState({ alertMessage: "Vui lòng chọn một chủ đề!" });

      setTimeout(() => {
        this.setState({ alertMessage: "" });
      }, 1000);

      return;
    }
    this.setState({
      isShuffuling: true,
    });

    this.GetRandomCard();
  };

  GetRandomCard = async () => {
    try {
      const apiResponse = await apiService.get("/cards/randomIds", {});
      console.log("random Card" + apiResponse);
      this.setState({
        randomCardKey: apiResponse,
      });
    } catch (error) {}
  };

  setTopic = () => {
    this.setState({
      openTopic: !this.state.openTopic,
    });
  };
  setTopicSelected = (index) => {
    this.setState({
      topicSelected: index,
    });
    this.setTopic();
  };
  setQuestionSelected = (index) => {
    this.setState({
      questionSelected: index,
    });
    this.setQuestion();
  };
  setQuestion = () => {
    this.setState({
      openQuestion: !this.state.openQuestion,
    });
  };

  render() {
    const {
      openTopic,
      openQuestion,
      selectedCard,
      isWatchingResult,
      isShuffuling,
      error,
      topicSelected,
      topics,
      randomCardKey,
    } = this.state;
    const cardImages = Array(78).fill(cardImage);
    const totalCards = 78; // Tổng số lá bài
    return (
      <>
        <div className="home">
          <div className="div">
            <Headers></Headers>

            <div>{error}</div>
            <div className="body">
              <p className="title"> NHẬN ĐƯỢC CÂU TRẢ LỜI BẠN CẦN VỚI</p>
              <div className="card-img">
                <img
                  className="wing-2"
                  alt="Group"
                  src="https://c.animaapp.com/bES86A8g/img/group-3@2x.png"
                />
                <p className="three-la">3 Lá</p>
                <img
                  className="wing-1"
                  alt="Group"
                  src="https://c.animaapp.com/bES86A8g/img/group-2@2x.png"
                />
              </div>
              <p className="card-text">BÀI TAROT</p>
              <div className="option-form">
                <div className="title-option">
                  <div className="option-bar" onClick={() => this.setTopic()}>
                    <div className="inner-bar">
                      <span className="option-text">
                        {this.state.topicSelected !== null
                          ? this.state.topicSelected.name
                          : "Chọn chủ đề"}
                      </span>
                    </div>
                  </div>
                  <div className={`dropdown-list ${openTopic ? "open" : ""}`}>
                    {topics.map((topic, index) => (
                      <div className="option-bar-value" key={index}>
                        <div
                          className="inner-bar"
                          onClick={() => this.setTopicSelected(topic)}
                        >
                          <span className="option-text">{topic.name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="question-option">
                  <div
                    className="option-bar"
                    onClick={() => this.setQuestion()}
                  >
                    <div className="inner-bar">
                      <span className="option-text">Chọn câu hỏi</span>
                    </div>
                  </div>
                  {openQuestion && <div>
                        {questions.map(() =(
                          
                        ))}
                    </div>}
                </div> */}
                <div className="shuffle-btn" onClick={() => this.ShuffleCard()}>
                  <div className="shuffle-btn-background">
                    <div className="shuffle-inner">
                      <div className="shuffle-text">XÀO BÀI</div>
                    </div>
                  </div>
                </div>
                {this.state.alertMessage && (
                  <div
                    class="alert alert-warning alert-dismissible fade show"
                    style={{
                      bottom: "20px",
                      left: "20px",
                      zIndex: 1000,
                    }}
                    role="alert"
                  >
                    <strong>Opps</strong> {this.state.alertMessage}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                    ></button>
                  </div>
                )}
              </div>
              {isShuffuling && (
                <motion.div
                  className="result-container"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <div className="shuffle-all-card">
                  <div className="card-container">
        {[...Array(totalCards)].map((_, index) => {
          const hasKey = index < randomCardKey.length;
          const cardKey = hasKey ? randomCardKey[index] : `placeholder-${index}`;

          return (
            <motion.div
              className={`card-button ${hasKey ? "selectable" : "disabled"} ${
                selectedCard.includes(randomCardKey[index]) ? "active" : ""
              }`}
              key={cardKey}
              onClick={() => hasKey && this.HandleCardClick(index)}
              initial={{
                x: (index % 12) * 30,
                y: -200,
                rotate: Math.random() * 360,
                opacity: 0,
              }}
              animate={{
                x: 0,
                y: 0,
                rotate: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <img
                className={`card-select ${hasKey ? "enabled" : "disabled"}`}
                alt={`Card ${index + 1}`}
                src={cardImage}
              />
            </motion.div>
          );
        })}
      </div>
                  </div>
                  <div className="meaning-btn">
                    <div
                      className="meaning-background"
                      onClick={() => this.HandleFliped()}
                    >
                      <div className="meaning-word">Xem ý nghĩa</div>
                    </div>
                  </div>
                  {isWatchingResult && (
                    <div className="detail-container">
                      <CardResult
                        image={cardImage}
                        selectedCardID={selectedCard}
                        isWatchingResult={isWatchingResult}
                        topicSelected={topicSelected}
                      ></CardResult>
                    </div>
                  )}
                </motion.div>
              )}
            </div>
            <Footers></Footers>
          </div>
        </div>
      </>
    );
  }
}
export default CardShuffle;
