import React from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "../../views/Blog/Blog";

class BlogRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Blog></Blog>}></Route>
      </Routes>
    );
  }
}
export default BlogRoutes;
