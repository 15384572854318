import React from "react";
import { Route, Routes } from "react-router-dom";
import Reader from "../../views/Reader/Reader";

class ReaderRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Reader></Reader>}></Route>
      </Routes>
    );
  }
}
export default ReaderRoutes;
