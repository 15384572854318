import React from "react";
import { Route, Routes } from "react-router-dom";   
import Signin from "../../views/Auth/SigninPage/Signin";
import SendEmail from "../../views/Auth/ResetPasswordRequest/SendEmail";
import LoginHandler from "../../components/LoginComponent/LoginForm";
import LoginPage from "../../views/Auth/LoginPage/LoginPage";

import SigninHandler from "../../components/SignUpComponent/SignUpForm";

class AuthRoutes extends React.Component {
  render() {
    const loginHandler = new LoginHandler(); 
    const signinHandler = new SigninHandler();
    return (
      <Routes>
         <Route path="/login" element={<LoginPage loginHandler={loginHandler}/>}/>
          <Route path="/signin" element={<Signin signinHandler={signinHandler}/>}/>
          <Route path="/Reset-password" element={<SendEmail></SendEmail>}></Route>          
      </Routes>
    );
  }
}
export default AuthRoutes;
