import logo from "./logo.svg";
import "./App.css";
import LaningPage from "./views/Landingpage/LaningPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";



function App() {
  return (
    <BrowserRouter>
      {/* <Router> */}
        <div className="App">
          <header className="App-header">
            <AppRoutes/>
          </header>
        </div>  
      {/* </Router> */}
   </BrowserRouter>
  );
}

export default App;
