import axios from "axios";
import React from "react";
import { apiService } from "../../env/apiService";

class LoginHandler extends React.Component {
  HandleLogin = async (username, password) => {
    if (!username || !password) {
      throw new Error("Vui điền đầy đủ thông tin.");
    }
    try {
      const body = {
        username,
        password,
      };
      return await apiService.post("/login", body);
    } catch (error) {
      throw new Error(error.message || "Đăng nhập thất bại.");
    }
  };
  render() {
    return <>{this.props.children}</>;
  }
}

export default LoginHandler;
