import React from "react";

import "../../Auth/style/Auth.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import LoginHandler from "../../../components/LoginComponent/LoginForm";

class LoginPage extends React.Component {
  state = {
    username: "",
    password: "",
    rememberMe: false,
    errorMessage: "",
  };
  HandleChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  HandleLogin = async () => {
    const { username, password } = this.state;
    try {
      const data = await this.props.loginHandler.HandleLogin(
        username,
        password
      );
      console.log("Login success:", data);
      this.setState({ errorMessage: "" });
    } catch (error) {
      console.error("Login failed:", error);
      this.setState({ errorMessage: error.message });
    }
  };
  render() {
    const { username, password, errorMessage } = this.state;
    return (
      <>
        <div className="login">
          <div className="div">
            <div className="card">
              <div className="overlap-group">
                <div className="ellipse" />

                <img
                  className="image"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image@2x.png"
                />

                <img
                  className="img"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image-1@2x.png"
                />

                <img
                  className="image-2"
                  alt="Image"
                  src="https://c.animaapp.com/v0sAEoVB/img/image-2@2x.png"
                />
              </div>
            </div>

            <div className="login-input">
              <div className="text-wrapper-9">ĐĂNG NHẬP</div>
              <div className="container">
                <div className="input-container">
                  <div className="input">
                    <div className="user-name">
                      <div className="overlap-group-4">
                        <label
                          htmlFor="username"
                          className="text-wrapper-8"
                        ></label>
                        <input
                          id="username"
                          type="text"
                          value={username}
                          placeholder="Tên đăng nhập"
                          className="input-field"
                          onChange={(e) => this.HandleChange(e, "username")}
                        />
                        <img
                          className="frame"
                          alt="Frame"
                          src="https://c.animaapp.com/v0sAEoVB/img/frame-1@2x.png"
                        />
                      </div>
                    </div>

                    <div className="password">
                      <div className="overlap-group-4">
                        <label htmlFor="password"></label>
                        <input
                          id="password"
                          type="password"
                          placeholder="Mật khẩu"
                          className="input-field"
                          value={password}
                          onChange={(e) => this.HandleChange(e, "password")}
                        />
                        <img
                          className="frame"
                          alt="Frame"
                          src="https://c.animaapp.com/v0sAEoVB/img/frame@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <div className="group-7">
                    <div className="remember-me">
                      <label className="custom-checkbox">
                        <input type="checkbox" className="checkbox-input" />
                        <div className="rectangle"></div>
                      </label>

                      <div className="text-wrapper-6">Ghi nhớ mật khẩu</div>
                    </div>
                    <Link className="text-wrapper-5" to="/Auth/Reset-password">
                      Quên mật khẩu ?
                    </Link>
                  </div>

                  <div className="group-6">
                    <button
                      className="overlap-group-3"
                      onClick={this.HandleLogin}
                    >
                      <div className="text-wrapper-4">Đăng nhập ngay</div>
                    </button>
                  </div>

                  <p className="b-n-kh-ng-c-t-i-kho">
                    <span className="text-wrapper-3">
                      Bạn không có tài khoản?{" "}
                    </span>

                    <Link className="span" to="/Auth/signin">
                      <span>Đăng ký tại đây!</span>
                    </Link>
                  </p>
                </div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <img
                      className="subtract"
                      alt="Subtract"
                      src="https://c.animaapp.com/v0sAEoVB/img/subtract@2x.png"
                    />
                    <p className="ng-nh-p-kh-c">
                      <span className="span">Đăng nhập khác</span>
                    </p>
                  </div>
                </div>
                <div className="group-2">
                  <a href="#" className="group-wrapper">
                    <div className="group-4">
                      <p className="p">
                        <span className="text-wrapper">Đăng nhập với </span>
                      </p>
                      <img
                        className="img-2"
                        alt="Google"
                        src="https://c.animaapp.com/v0sAEoVB/img/google-1@2x.png"
                      />
                    </div>
                  </a>
                  <a href="#" className="div-wrapper">
                    <a className="group-4">
                      <p className="p">
                        <span className="text-wrapper">Đăng nhập với </span>
                      </p>
                      <img
                        className="img-2"
                        alt="Facebook"
                        src="https://c.animaapp.com/v0sAEoVB/img/facebook-1@2x.png"
                      />
                    </a>
                  </a>
                </div>
              </div>
            </div>

            <Link to="/">
              <img
                className="logo"
                alt="Logo"
                src="https://c.animaapp.com/v0sAEoVB/img/logo@2x.png"
              />
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default LoginPage;
