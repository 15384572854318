import React from "react";
import styles from "./ReaderContainer.module.scss";

class ReaderTab extends React.Component {
  render() {
    const readerData = this.props.reader;
    return (
      <>
        <div className={styles["reader-container"]}>
          <div className={styles["image-reader"]}>
            <img
              className={styles["avatar-image"]}
              src="https://c.animaapp.com/0heN5NVk/img/image-6@2x.png"
              alt="avatar image"
            ></img>
          </div>
        </div>
      </>
    );
  }
}
export default ReaderTab;
