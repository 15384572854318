import React from "react";
import Headers from "../Layout/Header/Header";
import "../../styles/Global.scss";
import Footers from "../Layout/Footer/Footer";
import styles from "./ReaderStyle.module.scss";
import ReaderTab from "./ReaderDetail/ReaderInformation";

class Reader extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          <div className={styles.div}>
            <Headers></Headers>
            <div className={styles.introduction}>
              <div className={styles["image-container"]}>
                <img
                  className={styles["tarot-image"]}
                  alt=""
                  src="https://c.animaapp.com/0heN5NVk/img/image-12.png"
                />
                <div className={styles["overlay-text"]}>Tarot Reader</div>
              </div>
            </div>
            <ReaderTab></ReaderTab>
            <Footers></Footers>
          </div>
        </div>
      </>
    );
  }
}
export default Reader;
