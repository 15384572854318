
export const Card = (data) => ({
  cardName: data.cardName,
  imageUrl: data.imageUrl,
  topicMeaning:data.topicMeaning,
  
});
export const Topic = (data) =>({
  id: data.id,
  name : data.name
})