import React from "react";
import "./Landingpage.scss";
import "../../styles/Global.scss"
import Headers from "../Layout/Header/Header";
import Footers from "../Layout/Footer/Footer";

class LaningPage extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          <div className="div">
            <Headers></Headers>
            <div className="main-body">
              <div className="blog-div">
                <div className="blog">
                  <div className="watch-detail">
                    <div className="overlap-group-2">
                      <div className="rectangle" />

                      <div className="text-wrapper-9">XEM BÀI VIẾT</div>
                    </div>
                  </div>

                  <p className="p">
                    Bộ bài Tarot kết nối tiềm thức, giúp bạn khám phá bản thân
                    .....
                  </p>

                  <p className="text-wrapper-10">Tổng quan về Bộ bài Tarot</p>

                  <div className="text-wrapper-11">24/10/2024</div>

                  <img
                    className="rectangle-2"
                    alt="Rectangle"
                    src="https://c.animaapp.com/3r123mfJ/img/rectangle-45@2x.png"
                  />
                </div>

                <div className="blog-2">
                  <div className="watch-detail">
                    <div className="overlap-group-2">
                      <div className="rectangle" />

                      <div className="text-wrapper-9">XEM BÀI VIẾT</div>
                    </div>
                  </div>

                  <p className="text-wrapper-10">Tổng quan về Bộ bài Tarot</p>

                  <p className="p">
                    Bộ bài Tarot kết nối tiềm thức, giúp bạn khám phá bản thân
                    .....
                  </p>

                  <div className="text-wrapper-11">24/10/2024</div>

                  <img
                    className="rectangle-2"
                    alt="Rectangle"
                    src="https://c.animaapp.com/3r123mfJ/img/rectangle-45@2x.png"
                  />
                </div>

                <div className="blog-introduction">
                  <div className="watch-more">
                    <div className="overlap-group-3">
                      <div className="rectangle-3" />

                      <div className="text-wrapper-12">XEM THÊM</div>
                    </div>
                  </div>

                  <p className="text-wrapper-13">
                    Chào mừng bạn đến với blog Tarot chuyên chia sẻ về các trải
                    bài. Tại đây, bạn sẽ tìm thấy nhiều hướng dẫn và phân tích
                    chi tiết, giúp giải đáp thắc mắc và khám phá bản thân thông
                    qua từng trải bài.
                  </p>

                  <div className="text-wrapper-14">Blog</div>
                </div>
              </div>

              <div className="step-to-connect">
                <div className="step-div">
                  <div className="overlap-group-4">
                    <div className="rectangle-4" />

                    <div className="rectangle-5" />

                    <div className="step">
                      <p className="text-wrapper-15">
                        Bước 1: Chọn gói dịch vụ
                      </p>

                      <p className="text-wrapper-16">
                        Bắt đầu bằng cách lựa chọn gói dịch vụ phù hợp với nhu
                        cầu của mình về thời gian và loại hình tư vấn.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="overlap-wrapper">
                  <div className="overlap">
                    <div className="rectangle-6" />

                    <div className="rectangle-5" />

                    <div className="step-2">
                      <p className="text-wrapper-17">
                        Sau khi xác nhận lịch hẹn, bạn thực hiện thanh toán để
                        hoàn tất quy trình đặt lịch.
                      </p>

                      <div className="text-wrapper-15">Bước 3: Thanh toán</div>
                    </div>
                  </div>
                </div>

                <div className="overlap-group-wrapper">
                  <div className="overlap-group-4">
                    <div className="rectangle-4" />

                    <div className="rectangle-5" />

                    <div className="step-3">
                      <p className="text-wrapper-15">Bước 2: Đặt lịch hẹn</p>

                      <p className="text-wrapper-18">
                        Tiến hành đặt lịch hẹn với Tarot reader mà bạn muốn kết
                        nối. Hệ thống sẽ hiển thị các thời gian có sẵn để bạn dễ
                        dàng lựa chọn.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="div-wrapper">
                  <div className="overlap">
                    <div className="rectangle-6" />

                    <div className="rectangle-5" />

                    <div className="step-4">
                      <p className="text-wrapper-15">
                        Bước 4: Đăng ký thành công
                      </p>

                      <p className="text-wrapper-19">
                        Cuối cùng, bạn sẽ nhận được thông báo xác nhận đăng ký
                        thành công cùng thông tin chi tiết về buổi tư vấn sắp
                        tới.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-wrapper-20">
                Làm thế nào để kết nối 1:1 với Tarot Reader
              </p>

              <img
                className="image"
                alt="Image"
                src="https://c.animaapp.com/3r123mfJ/img/image-3.png"
              />

              <div className="tarot-reader-service">
                <div className="tarot-reader">
                  <div className="xem-ngat-btn">
                    <div className="overlap-group-3">
                      <div className="rectangle-3" />

                      <div className="text-wrapper-21">XEM NGAY</div>
                    </div>
                  </div>

                  <p className="text-wrapper-22">
                    Giúp bạn kết nối trực tuyến với các chuyên gia Tarot, cho
                    phép bạn chọn một reader phù hợp và đặt lịch hẹn dễ dàng.
                    Trong buổi tư vấn, bạn sẽ nhận được những phân tích sâu sắc
                    về tình yêu, sự nghiệp và các khía cạnh khác trong cuộc
                    sống. Các reader sẽ cung cấp những thông điệp cụ thể, giúp
                    bạn có cái nhìn rõ ràng hơn về tương lai và hỗ trợ bạn trong
                    việc giải quyết thắc mắc. Đây là cơ hội tuyệt vời để bạn tìm
                    kiếm sự thấu hiểu và bình an trong tâm hồn.
                  </p>

                  <div className="text-wrapper-23">Tarot Reader</div>
                </div>

                <img
                  className="image-2"
                  alt="Image"
                  src="https://c.animaapp.com/3r123mfJ/img/image.png"
                />
              </div>

              <div className="card-service">
                <div className="overlap-2">
                  <div className="trai-bai-service">
                    <div className="overlap-3">
                      <div className="div-2" />

                      <div className="div-3">
                        <div className="trai-bai-btn">
                          <div className="overlap-group-5">
                            <p className="text-wrapper-24">
                              Trải bài với chuyên gia
                            </p>
                          </div>
                        </div>

                        <p className="text-wrapper-25">
                          Nhận những lời khuyên riêng từ các chuyên gia để hiểu
                          rõ hơn về bản thân và cuộc sống. Mỗi lá bài sẽ giúp
                          bạn tìm ra những câu trả lời cho những vấn đề quan
                          trọng mà bạn đang đối mặt.
                        </p>

                        <div className="text-wrapper-15">
                          Trải bài tarot 1:1
                        </div>
                      </div>

                      <img
                        className="image-3"
                        alt="Image"
                        src="https://c.animaapp.com/3r123mfJ/img/image-1@2x.png"
                      />
                    </div>
                  </div>

                  <div className="boi-bai-service">
                    <div className="overlap-3">
                      <div className="div-2" />

                      <div className="div-3">
                        <div className="boi-bai-btn">
                          <div className="overlap-group-3">
                            <div className="rectangle-3" />

                            <div className="text-wrapper-26">
                              Bói bài online
                            </div>
                          </div>
                        </div>

                        <div className="text-wrapper-15">Bói bài Tarot</div>

                        <p className="text-wrapper-27">
                          Khám phá những bí ẩn của cuộc sống. Với những phương
                          pháp bói bài Tarot độc đáo, bạn sẽ nhận được những lời
                          khuyên quý báu giúp bạn định hình con đường phía
                          trước.
                        </p>
                      </div>

                      <img
                        className="image-3"
                        alt="Image"
                        src="https://c.animaapp.com/3r123mfJ/img/image-4@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-wrapper-28">Tính năng nổi bậc</div>

              <div className="body">
                <div className="card">
                  <div className="overlap-4">
                    <div className="card-background" />

                    <div className="card-2">
                      <div className="overlap-group-6">
                        <img
                          className="image-4"
                          alt="Image"
                          src="https://c.animaapp.com/3r123mfJ/img/image-2@2x.png"
                        />

                        <img
                          className="image-5"
                          alt="Image"
                          src="https://c.animaapp.com/3r123mfJ/img/image-5@2x.png"
                        />

                        <img
                          className="image-6"
                          alt="Image"
                          src="https://c.animaapp.com/3r123mfJ/img/image-6@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-button">
                  <div className="trai-bai-btn-2">
                    <div className="overlap-group-7">
                      <div className="rectangle-7" />

                      <div className="text-wrapper-29">TRẢI BÀI ONLINE</div>
                    </div>
                  </div>

                  <div className="dang-ky-button">
                    <div className="overlap-5">
                      <div className="rectangle-8" />

                      <div className="text-wrapper-30">ĐĂNG KÝ MIỄN PHÍ</div>
                    </div>
                  </div>
                </div>

                <div className="intro">
                  <p className="text-wrapper-31">
                    Chào mừng bạn đến với Soul Reader
                  </p>

                  <p className="text-wrapper-32">
                    Trang web chuyên kết nối bạn với các chuyên gia Tarot uy
                    tín, cung cấp dịch vụ giải mã trực tuyến. Tại đây, bạn có
                    thể nhận được sự hướng dẫn và phân tích sâu sắc từ các Tarot
                    reader, thông qua cả hình thức trực tuyến và kết nối trực
                    tiếp. Với Soul Reader, bạn dễ dàng khám phá những thông điệp
                    tiềm ẩn, tìm ra câu trả lời cho các vấn đề trong cuộc sống,
                    tình cảm và sự nghiệp, từ những chuyên gia giàu kinh nghiệm
                    trong lĩnh vực Tarot.
                  </p>
                </div>
              </div>
            </div>
            <Footers></Footers>
          </div>
        </div>
      </>
    );
  }
}
export default LaningPage;
