import React from "react";
import "./Footer.scss";
class Footers extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <div className="contact">
            <div className="text-wrapper">SĐT: 000-000-0000</div>

            <div className="text-wrapper-2">Email: abc@gmail.com</div>

            <div className="text-wrapper-3">Liên hệ</div>

            <div className="group">
              <img
                className="vector"
                alt="Vector"
                src="https://c.animaapp.com/3r123mfJ/img/vector@2x.png"
              />

              {/* <SocialIcons
                    color="original"
                    platform="tik-tok"
                    platformMessengerClassName="social-icons-instance"
                    platformTiktok="https://c.animaapp.com/3r123mfJ/img/social-icons@2x.png"
                  /> */}
              <img
                className="img"
                alt="Group"
                src="https://c.animaapp.com/3r123mfJ/img/group-47495@2x.png"
              />
            </div>
          </div>

          <div className="general-information">
            <div className="text-wrapper-4">Thông tin chung</div>

            <div className="text-wrapper-5">Hướng dẫn sử dụng</div>

            <div className="overlap-group">
              <div className="text-wrapper-6">Chính sách bảo mật</div>

              <div className="text-wrapper-7">Điều khoản sử dụng</div>
            </div>

            <div className="text-wrapper-8">Miễn trừ trách nhiệm</div>
          </div>

          <div className="website-name">
            <div className="element-soulreader"> © 2024 SoulReader</div>

            <img
              className="group-2"
              alt="Group"
              src="https://c.animaapp.com/3r123mfJ/img/group-47496@2x.png"
            />
          </div>

          <img
            className="line"
            alt="Line"
            src="https://c.animaapp.com/3r123mfJ/img/line-9.png"
          />
        </footer>
      </>
    );
  }
}
export default Footers