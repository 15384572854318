import React from "react";
import { Route, Routes } from "react-router-dom";
import CardShuffle from "../../views/Card/CardShuffle/CardShuffle";

class CardRoutes extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<CardShuffle></CardShuffle>}></Route>
      </Routes>
    );
  }
}
export default CardRoutes;
